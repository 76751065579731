[data-v-2d6beb8f] .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}
[data-v-2d6beb8f] .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}
[data-v-2d6beb8f] .el-dialog__body {
  padding: 20px;
}
.kicket-content[data-v-2d6beb8f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.kicket-content .kicket[data-v-2d6beb8f] {
  width: 140px;
  text-align: center;
  margin: 20px;
}
[data-v-2d6beb8f] .bjs-powered-by {
  display: none;
}